<template>
    <div class="p-fluid">
        <div class="formgrid grid">
            <div class="field col-12 md:col-6 lg:col-3">
                <label>Channel Distribusi</label>
                <InputText id="cdistribusi_name" v-model="forms.cdistribusi_name" required="true" autofocus :class="{ 'p-invalid': errorAdd.cdistribusi_name }" />
                <small class="p-invalid" style="color: red" v-if="errorAdd.cdistribusi_name" >{{ errorAdd.cdistribusi_name[0] }}</small >
            </div>
        </div>
    </div>
    <Button :loading="loading" label="Simpan" icon="pi pi-save" class="p-button-primary" @click="saveNew" />
</template>

<script>

import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,

            // addNew
            forms: {
                cdistribusi_name: null,
            },
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
    },
    computed:{
        ...mapGetters(['errorAdd']),
		...mapGetters("auth", ["user"]),
    },
    methods: {
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/master-channel-distribusi/create',
                data: {
                    "cdistribusi_name": this.forms.cdistribusi_name,
                },
            })
            .then((res) => {
                this.loading = false;
                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Disimpan', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    this.clearForms();
                    
                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Gagal Disimpan', life: 3000,});
            });
        },
        clearForms() {
            this.forms.cdistribusi_name = null;
        },
    }
}
</script>